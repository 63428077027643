/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {

        $('.mailpoet_nl_submit').on('click', function (e) {
          e.preventDefault();
          var _this = $(this).closest('.subscribe_nl');
          $.ajax({
            type: "POST",
            url: putsch.ajaxUrl,
            data: {
              action: 'insert_user_nl',
              email: _this.find('.email_nl').val()
            },
            success: function (response) {
              if (response.result) {
                _this.find('.mailpoet_validate_error').fadeIn(450).empty().text(response.message);
                // window.location.replace( response.data );
              } else {
                _this.find('.mailpoet_validate_error').fadeIn(450).empty().text(response.message);
              }
            },
            error: function (MLHttpRequest, textStatus, errorThrown) {
              console.log(errorThrown);
            },
            complete: function () {
            }
          });
          return false;
        });

        $('#reset-password-form').on('submit', function (e) {
          e.preventDefault();
          var _submit_button = $("#reset_password_button");
          var _this = $(this);
          $.ajax({
            type: "POST",
            url: putsch.ajaxUrl,
            data: {
              action: 'reset_password',
              email_login: _this.find('#email').val()
            },
            success: function (response) {
              if (response.result) {
                _this.closest('.modal-body').children('p').empty().text(response.message);
                // window.location.replace( response.data );
              } else {
                console.log(response);
                _this.closest('.modal-body').children('p').empty().text(response.message);
              }
            },
            error: function (MLHttpRequest, textStatus, errorThrown) {
              console.log(errorThrown);
            },
            complete: function () {
            }
          });
          /*$.post(
              putsch.ajax_url,
              {
                  'action': 'reset_password',
                  'email_login': _this.find('#email').val()
              },
              function(response){
                  if( response.result ){
                      _this.find('.modal-body').children('p').empty().text(response.message);
                      // window.location.replace( response.data );
                  }else{
                      console.log( response );
                      _this.find('.modal-body').children('p').empty().text(response.message);
                  }

              }
          );*/
          return false;
        });

        //$(".full-width-media iframe").parent().addClass('has-meda');

        // JavaScript to be fired on all pages
        console.log("%cPUTSCH vous souhaite la bienvenue !", "color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;");
        /* DOM Dimensions */
        $(window).resize(function () {
          var DOM = {
            W: $(window).width(),
            H: $(window).height(),
            dimensions: function () {
              console.clear();
              console.log("%c Width : " + this.W + "px \n%c Height : " + this.H + "px ", "background: cornflowerblue; color: white", "background: salmon; color: white");
            }
          };
          //DOM.dimensions();
        });
        $(".sub-menu li:nth-child(4n)").after("<div></div>");

        function onClickParentHeaderMenuItem(ev) {
          ev.preventDefault();

          if ($(ev.currentTarget.parentNode).hasClass('like-hover')) {
            $(ev.currentTarget.parentNode).removeClass('like-hover');
          }
          else {
            $('.header .menu-item-has-children.like-hover').removeClass('like-hover');
            $(ev.currentTarget.parentNode).addClass('like-hover');
          }
        }

        function cancelDefaulActionIfMobileNavIsUp(ev) {
          if ($(document.body).hasClass('nav-mobile')) {
            ev.preventDefault();

            if (ev.type == 'click') {
              onClickParentHeaderMenuItem(ev);
            }
          }
        }

        $('.header .menu-item-has-children > a').on('touchstart', onClickParentHeaderMenuItem);
        $('.header .menu-item-has-children > a').on('click', cancelDefaulActionIfMobileNavIsUp);
        $('.header .menu-item-has-children').on('mouseover', cancelDefaulActionIfMobileNavIsUp);

        // Layer Modals Connection & Registration
        $('.modal').on('hidden.bs.modal', function () {
          if ($('.modal:visible').length > 0) {
            $('body').addClass('modal-open');
          }
        });

        $(window).resize(function () {
          if ($(window).width() >= 991) {
            $('body').removeClass('nav-mobile');
            $('.header .menu-item-has-children.like-hover').removeClass('like-hover');
            $('#menu-btn').removeClass('open');
            $(".header nav").fadeIn('fast');
          } else {
            if (!$('body').hasClass('nav-mobile')) {
              $(".header nav").css('display', 'none');
            }
          }
        });

        $('#menu-btn').click(function (e) {
          $(this).toggleClass('open');
          if ($('body').hasClass('nav-mobile')) {
            $(".header nav").fadeToggle('fast', function () {
              $('body').toggleClass('nav-mobile');
            });
          } else {
            $('body').toggleClass('nav-mobile');
            $(".header nav").fadeToggle('slow');
          }
        });

        $(".connection a").click(function (e) {
          e.preventDefault();
          $('#connectModal').modal('show');
        });

        // $('#register').click(function (e) {
        //   e.preventDefault();
        //   $('#registerModal').modal('show');
        //   $('#connectModal').modal('hide');
        // });

        $('#connect').click(function (e) {
          e.preventDefault();
          $('#connectModal').modal('show');
          // $('#registerModal').modal('hide');
        });

        $('.forgot-password-modal').click(function (e) {
          e.preventDefault();
          $('#passwordModal').modal('show');
          $('#connectModal').modal('hide');
        });

        $('#connect-pass').click(function (e) {
          e.preventDefault();
          $('#connectModal').modal('show');
          $('#passwordModal').modal('hide');
        });

        $("#login_form").submit(function (e) {
          e.preventDefault();
          $.ajax({
            type: "POST",
            url: putsch.ajaxUrl,
            data: {
              action: 'login',
              user: $('#login_user').val(),
              pass: $('#login_pass').val()
            },
            success: function (data) {
              //console.log(data);
              if (data.success) {
                $('#form_login_message').removeClass('alert-danger').addClass('alert-success').html('Vous êtes connecté').show();
                setTimeout(window.location.reload.bind(window.location), 1000);
              } else {
                $('#form_login_message').show();
              }
            },
            error: function (MLHttpRequest, textStatus, errorThrown) {
              console.log(errorThrown);
              $('#form_login_message').show();
            },
            complete: function () {
            }
          });
        });

        $('.newsletter').click(function () {
          $('html, body').css('overflow-y', 'scroll');
          $('#menu-btn').toggleClass('open');
          if ($('body').hasClass('nav-mobile')) {
            $(".header nav").fadeToggle('fast', function () {
              $('body').toggleClass('nav-mobile');
            });
          }

          $('html, body').animate({
            scrollTop: $('footer[role="contentinfo"]').offset().top
          }, 'slow');
        });

      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        UIHeader.init(document.querySelector('.header'));
      }
    },
    // Home page
    'home': {
      init: function () {
        function homeArticleCarousel() {
          if (window.matchMedia("(max-width: 768px)").matches) {
            $('.owl-carousel').owlCarousel({
              nav: false,
              items: 1,
              lazyLoad: true,
              stagePadding: 10,
              margin: 0,
              dots: false
            });
          } else {
            $('.owl-carousel').owlCarousel('destroy');
          }
        }

        homeArticleCarousel();

        $(window).on('resize', function () {
          homeArticleCarousel()
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // le dessin de bouchard.
    'post_type_archive_dessin': {
      finalize: function () {
        $(this).attr('loading', false);
        $('#load-more').click(function () {
          if ($(this).hasClass('disabled')) return;
          $(this).addClass('disabled');
          $('#load-more a').html("Chargement...");
          $.ajax({
            type: "GET",
            url: putsch.ajaxUrl,
            data: {
              action: 'loadMore',
              type: 'dessin',
              offset: displayed_offset
            },
            success: function (htmlContent) {
              console.log('complete');
              console.log(htmlContent);
              $('.home-picture').append(htmlContent);
            },
            error: function (MLHttpRequest, textStatus, errorThrown) {
              console.log(errorThrown);
            },
            complete: function () {
              $('#load-more').removeClass('disabled');
              $('#load-more a').html("Voir Plus");
            }
          });
        });
      }
    },

    // Category pages
    'category': {
      finalize: function () {
        $(this).attr('loading', false);
        $('#load-more').click(function () {
          if ($(this).hasClass('disabled')) return;
          $(this).addClass('disabled');
          $('#load-more a').html("Chargement...");
          $.ajax({
            type: "GET",
            url: putsch.ajaxUrl,
            data: {
              action: 'loadMore',
              category: putsch.category,
              offset: displayed_offset
            },
            success: function (htmlContent) {
              $('#posts-container').append(htmlContent);
            },
            error: function (MLHttpRequest, textStatus, errorThrown) {
              console.log(errorThrown);
            },
            complete: function () {
              $('#load-more').removeClass('disabled');
              $('#load-more a').html("Voir Plus");
            }
          });
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    },
    // Abo page
    'abonnement': {
      init: function () {
        // JavaScript to be fired on the abo page
        var contents = [];
        var body = $('body');
        // boutons de selection de l'abonnement
        var Abos = {
          mensuel: '.abos > span:first-child',
          annuel: '.abos > span:last-child'
        };

        var opacify = function () {
          if ($('#select_offer_period').prop('checked')) {
            // on selectionne l'abo "annuel"
            data = putsch.offers.Year; // variable putsch définie dans extra.php
            $(Abos.mensuel).css('opacity', '0.5');
            $(Abos.annuel).css('opacity', '1');
          } else {
            // on selectionne l'abo "mensuel"
            data = putsch.offers.Month; // variable putsch définie dans extra.php
            $(Abos.mensuel).css('opacity', '1');
            $(Abos.annuel).css('opacity', '0.5');
          }

          // affichage du titre.
          $('#offer_web h3').fadeOut(150, function () {
            $(this).text(data.web.name).fadeIn(150);
          });
          $('#offer_print h3').fadeOut(150, function () {
            $(this).text(data.print.name).fadeIn(150);
          });
          // affichage des prix
          $('#offer_web strong').fadeOut(150, function () {
            $(this).text(data.web.price).fadeIn(150);
          });
          $('#offer_print strong').fadeOut(150, function () {
            $(this).text(data.print.price).fadeIn(150);
          });
          // affichage de la période
          $('mark').fadeOut(150, function () {
            $(this).text(data.web.recur).fadeIn(150);
          });
          // définition des offres
          $('#offer_web button').attr('offer', data.web.id);
          $('#offer_print button').attr('offer', data.print.id);
        };

        body.on('change', '#select_offer_period', function () {
          opacify();
        });

        body.on('click', Abos.mensuel, function () {
          $('input').prop('checked', false);
          opacify();
        });

        body.on('click', Abos.annuel, function () {
          $('input').prop('checked', true);
          opacify();
        });

        body.on('click', '.paywallAction', function () {
          formData = $('#ajax_form');
          // si pas valide on simule un submit
          if (typeof formData[0] !== "undefined" && !formData[0].checkValidity() && $(this).attr('step') === "create_account_submit") {
            $('<button>').appendTo(formData).click().remove();
            return;
          }

          $("#ajax_form input").removeClass('error').attr('title', '');

          $.ajax({
            type: "POST",
            url: putsch.ajaxUrl,
            data: {
              action: 'payWall',
              offer: $(this).attr('offer'),
              step: $(this).attr('step'),
              formData: $('#ajax_form').serializeArray()
            },
            success: function (data) {
              if (typeof data === 'object') {
                // todo : à imtégrer ?
                if (typeof data.action !== 'undefined') {
                  switch (data.action) {
                    case'redirect':
                      window.location.href = data.url;
                      break;
                  }
                } else {
                  if (data.success === false) {
                    // il y a une erreur, on affiche les erreurs.
                    for (var field in data.data) {
                      var value = data.data[field];
                      $('small.feedback').remove();
                      $("#ajax_form input[name=" + field + "]").addClass('error').attr('title', value).focus().parent().append('<small class="feedback">'+value+'</small>');
                      //console.log(field + ' = ' + value);
                      if (field == 'alert') alert(value);
                    }
                  }
                }
              } else {
                $('.paywall').fadeOut(150, function () {
                  $('.paywall').html(data).fadeIn(150);
                  opacify();
                  $("#ajax_form").on("submit", function (e) {
                    e.preventDefault();
                    return false;
                  });
                });
              }
            },
            error: function (MLHttpRequest, textStatus, errorThrown) {
              console.log(errorThrown);
            }
          });
        });
      },
      finalize: function () {

      }
    },
    // Account page
    'mon_compte': {
      init: function () {
        // JavaScript to be fired on the Account page
        $(".chosen-select").chosen({
          disable_search: true,
          width: '100%'
        });
      },
      finalize: function () {
      },
    },
    // Single pages
    'single': {
      init: function () {
        function articleCarousel() {
          if (window.matchMedia("(max-width: 768px)").matches) {
            $('.owl-carousel').owlCarousel({
              nav: false,
              items: 1,
              lazyLoad: true,
              stagePadding: 10,
              margin: 0,
              dots: false
            })
          } else {
            $('.owl-carousel').owlCarousel('destroy');
          }
        }

        articleCarousel();

        $(window).on('resize', function () {
          articleCarousel()
        });

        $('#bt-comment-chro').click(function () {
          $('html,body').animate({
              scrollTop: $('#comments').offset().top
            },
            'slow');
        });

      },
      finalize: function () {
        var excluded = $('article iframe, article blockquote, article .imgs');
        excluded.each(function (k, v) {
          //$(v).closest("p").html(v.html());
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
